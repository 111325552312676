<template>
    <div>
        <CCard class="zw-page">
            <CCardBody>
                <zw-table ref="table"
                          tableName="customers"
                          base-table="customer"
                          columnsPrefix="customer.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :selectedSource="selectedSource"
                          title="common.title.customers"
                          :actions-list="getActions()"
                >
                    <template #cell(total)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item v-if="row.item._edit"
                                             @click="$root.$children[0].openModal('customer-modal', {customerId:row.item.id}, refreshTable)">
                                <font-awesome-icon class="mr-2" icon="edit"/>
                                {{ $t('common.title.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="createProject(row.item.id, row.item)">
                                <font-awesome-icon class="mr-2" icon="plus"/>
                                {{ $t('common.button.create') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="row.item._delete"
                                @click="deleteCustomer(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.title.delete') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="sendMail(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="envelope"/>
                                {{ $t('common.button.send_mail') }}
                            </b-dropdown-item>
                            <b-dropdown-item v-if="row.item.default_mobile"
                                             @click="openWhatsapp(row.item.default_mobile)">
                                <font-awesome-icon :icon="['fab', 'whatsapp']" class="mr-2 text-success"/>
                                {{ $t('common.button.whatsapp') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                v-if="row.item.total && $root.hasAccess('sales','view_all')"
                                @click="$root.$children[0].openModal('customer-modal', {customerId:row.item.id,tab:'offerings'})">
                                {{ $t('common.button.operations') }}&nbsp;({{ row.item.total }})
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('events-modal', {id: row.item.id,type:'customer'})"
                            >
                                <font-awesome-icon class="mr-2" icon="calendar"/>
                                {{ $t('common.button.events') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'Customers',
    mixins: [commonTable],
    data() {
        return {
            saveFilters: true,
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getWorkflowTriggers']),
        ...mapGetters('Customer', ['getCustomersTable']),
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Customer/fetchCustomers', 'getCustomersTable');
        },
        deleteCustomer(id) {
            this.$removeConfirm('Customer/deleteCustomer', {
                customerId: id
            }, this.refreshTable)
        },
        sendMail(id) {
            this.$root.$children[0].openModal('email-modal', {
                documentId: id,
                docType: 'customer_email',
            }, this.shown, {})
        },
        openWhatsapp(mobile) {
            if (mobile) {
                let cleaned = mobile.replace(/[^\d+]/g, '');
                if (cleaned.startsWith('+')) {
                    cleaned = cleaned.substring(1);
                }
                window.open('https://api.whatsapp.com/send/?phone=' + cleaned, '_blank').focus()
            }
        },
        deleteSelected() {
            this.$removeConfirm('Customer/deleteCustomers', this.selected.map(row => row.id), this.refreshTable)
        },
        createProject(id, data) {
            this.$root.$children[0].openModal('project-form', {customer: data}, null, {width: '800px'})
        },
        openChangeAttribute() {
            this.$root.$children[0].openModal('change-attribute-modal', {
                type: 'customer',
                selectedData: this.getSelectedData('customers'),
            }, this.refreshTable, {width: '800px'})
        },
        sendMails() {
            const selected = this.selected.length || this.selectedSource != 'selected'
            this.$root.$children[0].openModal('email-modal', {
                type: selected ? 'customer' : null,
                selectedData: selected ? this.getSelectedData('customers') : null,
                multiple: true
            }, this.refreshTable, {width: '800px'})
        },
        getActions() {
            let actions = [
                {
                    title: this.$t('common.button.createCustomer'),
                    icon: 'plus',
                    hide: !this.$root.hasAccess('customers', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('customer-modal', {}, this.refreshTable)
                    },
                },
                {
                    title: this.$t('article.button.change_attribute'),
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    icon: 'list',
                    hide: !this.$root.hasAccess('customers', 'create'),
                    click: () => {
                        this.openChangeAttribute()
                    }
                },
                {
                    title: this.$t('common.button.send_email'),
                    icon: 'envelope',
                    click: () => {
                        this.sendMails()
                    }
                },
                {
                    title: this.$t('article.button.delete_selected'),
                    icon: 'minus',
                    hide: !this.$root.hasAccess('customers', 'delete'),
                    disabled: !this.selected.length,
                    click: () => {
                        this.deleteSelected()
                    },
                },
                {
                    title: this.$t('common.button.import'),
                    icon: 'download',
                    hide: !this.$root.hasAccess('customers', 'create'),
                    click: () => {
                        this.$root.$children[0].openModal('import-modal', {type: 'customer'}, this.refreshTable, {width: '800px'})
                    },
                },
            ]

            this.getWorkflowTriggers()['customer'].forEach(trigger => {
                actions.push({
                    title: 'Trigger: ' + trigger.name,
                    icon: 'play',
                    disabled: !(this.selected.length || this.selectedSource != 'selected'),
                    click: () => {
                        this.loading = true

                        this.$store.dispatch('Workflow/executeTrigger', {
                            id: trigger.id,
                            ...this.getSelectedData('customers')
                        })
                            .then((response) => {
                                if (response.data.text) {
                                    this.$root.showMessage('Processing...', response.data.text, 'info')
                                    this.$root.$children[0].showJobsToast(response.data.result.batch_group)
                                }
                            })
                            .finally(() => {
                                this.loading = false
                            })
                    },
                },);
            })

            return actions
        }
    }
}
</script>